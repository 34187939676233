.location-page {
  padding: 120px 20px 20px 20px;
  background-color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
  font-family: 'Outfit';
}

.header {
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding-top: 10px;
  background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.738) 19%, rgba(0, 0, 0, 0.541) 34%, rgba(0, 0, 0, 0.382) 47%, rgba(0, 0, 0, 0.278) 56.5%, rgba(0, 0, 0, 0.194) 65%, rgba(0, 0, 0, 0.126) 73%, rgba(0, 0, 0, 0.075) 80.2%, rgba(0, 0, 0, 0.042) 86.1%, rgba(0, 0, 0, 0.021) 91%, rgba(0, 0, 0, 0.008) 95.2%, rgba(0, 0, 0, 0.002) 98.2%, transparent 100%);
  z-index: 9;
}

.pageHeader {
  font-size: 40px;
  text-align: center;
  margin: 0;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}

.locationHeader {
  text-align: center;
  font-family: 'Caveat';
  font-size: 20px;
  position: relative;
  margin: 0 auto 20px;
  width: fit-content;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}

.locationHeader::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  width: 20px;
  background-color: white;
  transform: translateX(calc(-100% + -10px));
  filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.5));
}

.locationHeader::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  height: 1px;
  width: 20px;
  background-color: white;
  transform: translateX(calc(100% + 10px));
  filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.5));
}

.photo-gallery {
  column-gap: 20px;
  padding: 0;
  column-count: 4;
  margin: 0 auto 40px;
  max-width: 1200px;
}

.photo-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  position: relative;
  z-index: 2;
  vertical-align: middle;
}

.photo-gallery img:hover {
  cursor: pointer;
}

.photo-gallery img.loaded {
  opacity: 1;
}

.imgWrapper {
  position: relative;
  break-inside: avoid;
  margin-bottom: 20px;
}

.imgWrapper:hover {
  z-index: 5;
}

.imgWrapper::after {
  content: '';
  width: 100%;
  height: 100%;
  background-image: url('/public/utilAssets/Icons/eye.svg');
  background-color: rgba(0, 0, 0, 0.7);
  background-size: 35px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: .3s cubic-bezier(.7, 0, .3, 1);
  backdrop-filter: blur(2px);
}

.imgWrapper:hover::after {
  opacity: 1;
}

.imgWrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--bg-url);
  background-size: cover;
  z-index: 1;
  filter: blur(0px);
  transition: .3s ease-in-out;
}

.imgWrapper:hover::before {
  filter: blur(20px);
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
  color: #aaa;
}

.loading {
  visibility: hidden;
}

.returnBtn {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 18px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  z-index: 1000;
  transition: .15s ease-in-out;
  font-family: 'Outfit';
  font-weight: 600;
  color: black;
  background-color: white;
  opacity: 0.4;
}

.returnBtn:hover {
  background-color: white;
  color: black;
  opacity: 1;
}

.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  margin: auto;
  display: block;
  max-width: 80%;
  max-height: 80%;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width:720px) {
  .photo-gallery {
    column-gap: 15px;
    column-count: 2;
  }
}