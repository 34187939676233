@import url('https://fonts.googleapis.com/css2?family=Outfit:ital,wght@0,100..900;1,100..900&display=swap');

.loading-screen * {
  font-family: 'Outfit';
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  z-index: 9999;
}

.loadingText {
  width: fit-content;
  font-weight: 900;
  font-size: 70px;
  margin-bottom: -10px;
  letter-spacing: 2px;
  background-clip: text;
  color: transparent;
}

.progressText {
  font-size: 20px;
  font-weight: 700;
}