@import url('https://fonts.googleapis.com/css2?family=Outfit:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');

body {
  overflow: scroll;
}

::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  background: transparent;
}

a {
  text-decoration: none;
}

.slide-enter {
  /* transform: translateY(150%); */
  opacity: 0;
}

.slide-enter-active {
  /* transform: translateY(0); */
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}

.slide-exit {
  /* transform: translateY(0); */
  opacity: 1;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

.slide-exit-active {
  /* transform: translateY(150%); */
  opacity: 0;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  transition: opacity 1000ms ease-in-out;
}

.navbar {
  background: #fff;
  border-radius: 50px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  z-index: 10;
  padding: 4px 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar>* {
  display: flex;
  align-items: center;
  margin: 0;
  transition: .3s ease-in-out;
  padding: 6px;
  border-radius: 50px;
  position: relative;
}

.navbar svg {
  transition: .3s ease-in-out;
}

.navbar>*:hover {
  margin: 0 5px;
  background: black;
}

.navbar>*:hover svg {
  filter: invert(1)
}

.navbar>*:nth-child(1):hover {
  margin: 0 5px 0 0;
}

.navbar>*:last-child:hover {
  margin: 0 0 0 5px;
}

.menu-btn {
  background: transparent;
  border: 0px;
  position: relative;
  cursor: pointer;
}

.menu-btn svg {
  transition: .3s ease-in-out;
  fill: black;
}

.menu-btn:hover svg {
  fill: #000;
}

svg.instagramIcon {
  color: black;
  position: relative;
}

.mailIcon {
  color: black;
}

.tooltip {
  opacity: 0;
  position: absolute;
  top: -20px;
  background: white;
  border-radius: 4px;
  padding: 4px 6px;
  left: 50%;
  transform: translate(-50%, -100%);
  transition: .3s ease-in-out;
  font-family: 'Outfit';
  font-size: 12px;
  font-weight: 600;
  width: fit-content;
  white-space: nowrap;
  color: black;
}

a:hover .tooltip,
button:hover .tooltip {
  opacity: 1;
  top: -10px;
}