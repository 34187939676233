.offcanvas-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
    color: #fff;
    transition: opacity 0.3s ease-in-out;
    z-index: 10000;
    opacity: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.offcanvas-menu.open {
    pointer-events: all;
    opacity: 1;
}

.close-btn {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 5px;
}

.portfolio_folders,
.otherPortfolio {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: fit-content;
    gap: 20px;
    max-width: 900px;
    margin: 0 auto;
    font-family: 'Outfit';
}

.portfolio_folders a,
.otherPortfolio a {
    background-color: white;
    color: black;
    font-family: 'Outfit';
    padding: 10px 20px;
    border-radius: 4px;
    transition: .3s ease-in-out;
    position: relative;
}

.portfolio_folders img,
.portImage {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    filter: drop-shadow(2px 4px 6px black);
}

.portfolio_folders a:hover,
.otherPortfolio a:hover {
    font-weight: 900;
}

.otherPortfolio {
    margin-top: 40px;
}

.portfolio_folders p,
.otherPortfolio p {
    flex: 1 0 100%;
    margin-bottom: 0;
    text-align: center;
}

@media only screen and (max-width:720px) {

    .portfolio_folders a,
    .otherPortfolio a {
        font-size: 10px;
        padding: 8px 16px;
    }

    .portfolio_folders,
    .otherPortfolio {
        gap: 15px;
        padding: 0 20px;
    }

    .otherPortfolio {
        margin-top: 20px;
    }

    .portfolio_folders img,
    .portImage {
        width: 15px !important;
        height: 10px !important;
    }
}