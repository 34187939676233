.logo {
  mask-repeat: no-repeat;
  mask-size: 100%;
  color: white;
  font-family: 'Outfit';
  font-weight: 700;
  font-size: 480px;
  line-height: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 20;
  height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 25px;
}

.mapbox-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.overview-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  z-index: 1000;
  transition: .15s ease-in-out;
  font-family: 'Outfit';
  font-weight: 600;
  color: #FE6724;
}

.overview-button:hover {
  background-color: #cf4409;
  color: black;
}

.mapboxgl-control-container {
  display: none;
}

.mapboxgl-popup-content {
  padding: 0px 0px;
  background-color: transparent;
  transform: translateY(-10px);
  color: white;
  font-size: 17px;
  text-shadow: 0px 0px 10px #fe6524d3;
}

.mapboxgl-popup-content strong::first-letter {
  font-size: 20px;
}

.mapboxgl-popup-content strong {
  font-family: "Outfit", sans-serif;
  font-weight: 900;
  letter-spacing: 1px;
  font-size: 15px;
  text-transform: uppercase;
  display: block;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-close-button:focus-visible {
  outline: none !important;
}

button.mapboxgl-popup-close-button {
  color: white !important;
  display: none;
}

/* Add this to your CSS file */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.popup-fade-in {
  animation: fadeIn 0.1s forwards;
}

.popup-fade-out {
  animation: fadeOut 0.1s forwards;
}